import React, { FC, useContext, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Typo from '../../Components/Typo';
import RoundedButton from '../../Components/RoundedButton';
import { Link, NavLink, useHistory } from 'react-router-dom';
import gsap from 'gsap';
import { uniqueId } from 'lodash';
import { NavigatorWheelContext } from '../../Contexts/NavigatorWheelContext';
import * as H from 'history';
import { APP_HEADER_HEIGHT } from '../../Constants';
import clsx from 'clsx';
import { useNavigation } from './useNavigation';


export interface NavBarProps {

}

const NavBar: FC<NavBarProps> = (props) => {
    const classes = useStyles();
    // const { setCurSection } = useContext(NavigatorWheelContext);
    const history = useHistory();
    const { NAVS, isLinkActive, id } = useNavigation();

    // useEffect(() => {
    //     gsap.timeline()
    //         .fromTo(`.${id}-menu-item`,
    //             { x: 80, opacity: 0, },
    //             { x: 0, stagger: 0.2, delay: 1.5, opacity: 1 }
    //         )
    // }, [])


    // const handleNavigation = (to: string) => () => {
    //     if (to === 'features') {
    //         setCurSection(0);
    //     }
    // }

    // const NAVS = [
    //     { label: 'HOME', url: '/', id: 'home' },
    //     { label: 'FEATURES', url: '/features', id: 'features', onClick: handleNavigation('features') },
    //     { label: 'BLOG', url: '/blog', id: 'blog' }
    // ]



    // const isLinkActive = (route: string) => (match: any, location: H.Location) => {
    //     return route === location.pathname;
    // }

    return (
        <Box display='flex' alignItems='center'>
            {NAVS.map(nav => nav.external ? (
                <a href={nav.url} target={nav.target} key={nav.id} className={clsx(classes.navlink, `${id}-menu-item`)} >
                    {nav.label}
                </a>
            ) : (
                <NavLink onClick={nav.onClick} key={nav.id} activeClassName={classes.active} className={clsx(classes.navlink, `${id}-menu-item`)} isActive={isLinkActive(nav.url)} to={nav.url} >
                    {nav.label}
                </NavLink>
            ))}

            <Box mx={2} className={`${id}-menu-item`}>
                <Link to="/request-demo">
                    <RoundedButton animationDelay={1.5} variant='contained' color='secondary'>GET DEMO</RoundedButton>
                </Link>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    active: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main + ' !important',
        '&:visited': {
            color: theme.palette.primary.main + ' !important',
        }
    },
    navlink: {
        margin: theme.spacing(0, 2),
        textDecoration: 'none',
        lineHeight: APP_HEADER_HEIGHT + 'px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.primary,
        '&:visited': {
            color: theme.palette.text.primary
        }

    }
}));

export default NavBar;